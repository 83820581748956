<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="~@/assets/logo.png" /> -->
    <h1>Home</h1>
  </div>
</template>

<script>

export default {
  name: "Home",
  mounted() {
    this.$router.push({name: "room"});
  },
};
</script>
